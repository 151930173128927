import React, { useState, useEffect, useRef } from "react";
import images from '../../utils/images';
import TrackEvent from "../../utils/events";

const DownloadButton = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const modalRef = useRef(null);

    const toggleModal = () => {
        if (!isModalOpen) {
            TrackEvent("desktop_download_app_button_clicked")
        }
        setModalOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setModalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex items-center">
            {/* Download Button */}
            <button
                onClick={toggleModal}
                className="bg-[#213A8F] border border-[#FFFFFF] rounded-[8px] flex items-center gap-3 justify-center px-5 py-3"
            >
                <span className="text-[#FFFFFF] text-[16px] font-bold">Download</span>
                <img
                    className="w-[18px] h-[18px]"
                    src={images.googlePlayIconUrl}
                    alt="Google Play icon"
                />
                <img
                    className="h-[18px] w-auto"
                    src={images.appStoreIconUrl}
                    alt="App Store icon"
                />
            </button>

            {/* Modal */}
            {isModalOpen && (
                <div
                    ref={modalRef}
                    className="absolute top-full right-0 mt-2 bg-white shadow-md rounded-[10px] flex flex-col w-[165%] p-12 z-50 gap-y-4"
                >
                    {/* Google Play Download */}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.finurja.finurja_mobile&hl=en_IN"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                            TrackEvent("desktop_download_android_app_button_clicked")
                            setModalOpen(false);
                        }}
                    >
                        <div className="flex items-center justify-between space-x-3 py-2 px-4 bg-[#213A8F] rounded-[8px] mt-[5%] border-[#182A68] border-[1.76px]">
                            <div className="flex flex-col justify-center">
                                <span className="text-[12px] font-medium text-white leading-[14px] whitespace-nowrap">
                                    GET IT ON
                                </span>
                                <span className="text-[20px] font-medium text-white leading-[23px]">
                                    Google Play
                                </span>
                            </div>
                            <img
                                className="h-[32px] w-auto"
                                src={images.googlePlayIconUrl}
                                alt="Google Play icon"
                            />
                        </div>
                    </a>

                    {/* App Store Download */}
                    <a
                        href="https://apps.apple.com/in/app/finurja-banks-fds-nps-epf-mf/id6736566130"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                            TrackEvent("desktop_download_ios_app_button_clicked")
                            setModalOpen(false);
                        }}
                    >
                        <div className="flex items-center justify-between space-x-3 py-2 px-4 bg-[#213A8F] rounded-[8px] mt-[5%] border-[#182A68] border-[1.76px]">
                            <div className="flex flex-col justify-center">
                                <span className="text-[12px] font-medium text-white leading-[14px] whitespace-nowrap">
                                    GET IT ON
                                </span>
                                <span className="text-[20px] font-medium text-white leading-[23px]">
                                    App Store
                                </span>
                            </div>
                            <img
                                className="h-[32px] w-auto"
                                src={images.appStoreIconUrl}
                                alt="App Store icon"
                            />
                        </div>
                    </a>
                </div>
            )}
        </div>
    );
};

export default DownloadButton;
